import React from 'react';
import '../../../Authentication/LandingPage/style.scss';
import { Link } from 'react-router-dom';
import { useWebProperties } from '../../webProperties';
import { useSelector } from 'react-redux';
import Layout from '../../../Optimisation/MetaData/Layout/Layout';

export default function Partners() {

    const { APP_NAME, IMG_SRC } = useWebProperties();
    const webProperties = useSelector(state => state.landingReducer.webProperties)
    const properties = useSelector(state => state.landingReducer.properties)

    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      };

    return (
        <Layout pageName='careers'>
            <div>
                <nav className="navbar sticky-top navbar-expand-lg navbar-light" style={{ backgroundColor: webProperties?.PRIMARY_COLOR !== "#47974b" ? webProperties?.PRIMARY_COLOR : '#47974b' }}>
                    <button style={{ background: 'white' }} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01"
                        aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
                        {/* eslint-disable-next-line */}
                        <a className="navbar-brand"><Link to={{ pathname: "/" }}>
                            {/* <img src="images/common/agile2.png" width="170" alt="" /></Link> */}
                            <img className="agile-supportlogo" src={properties?.IMG_SRC || IMG_SRC} width="170" alt="" /></Link>

                        </a>
                        <form className="form-inline my-2 my-lg-0 ml-auto" style={{ paddingTop: "16px" }}>
                            {/* <!-- <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search"> --> */}
                            {/* eslint-disable-next-line */}
                            <a className="btn my-2 my-sm-0 ml-auto" id="loginbtn" type="submit"><Link style={{ color: webProperties?.PRIMARY_COLOR !== "#47974b" ? webProperties?.PRIMARY_COLOR : '#47974b' }} to={{ pathname: "/login" }}>Login</Link></a>
                            {/* eslint-disable-next-line */}
                            <a style={{ backgroundColor: webProperties?.PRIMARY_COLOR !== "#47974b" ? webProperties?.PRIMARY_COLOR : '#47974b' }} className="btn my-2 my-sm-0" id="signupbtn-support" type="submit"><Link style={{ color: 'white' }} to={{ pathname: "/" }}>Home </Link></a>
                            {/* support page button */}
                        </form>
                    </div>
                </nav>
                <div style={{ background: 'linear-gradient(to bottom right, #4f4e4a, #12100b)', padding: '20px' }}>
                <div className="d-flex justify-content-end" >
                    <button className=" text-white" style={{backgroundColor:'transparent', border:0}} onClick={() => scrollToSection('introduction')}>Introduction</button>
                    <button className="text-white" style={{backgroundColor:'transparent', border:0}} onClick={() => scrollToSection('partner-types')}>Partner Types</button>
                    <button className="text-white" style={{backgroundColor:'transparent', border:0}} onClick={() => scrollToSection('partner-levels')}>Partner Levels</button>
                    <button className="text-white" style={{backgroundColor:'transparent', border:0}} onClick={() => scrollToSection('customers')}>Our customers</button>
                </div>
                    <h1 style={{ fontSize: '50px', fontWeight: 'bold', color: 'white', textAlign: 'center', marginTop: '100px' }}>
                        WeTasker Partner Network
                    </h1>
                    <section id="introduction" style={{ color: 'white', marginTop: '50px', paddingTop:'100px' }}>
                        <h2 style={{ color: 'white', fontSize: '35px', fontWeight: 'bold', textAlign: 'center' }}>Introduction</h2>
                        <h2 style={{ color: 'white', fontSize: '25px', fontWeight: 'bold', textAlign: 'center',lineHeight: '1.6' }}>WeTasker: Powering the Feature of Work Through Intelligent Collabration</h2>
                        <div className='px-5'>
                        <p style={{ color: 'white', fontSize: '20px', lineHeight: '1.6' }}>At WeTasker, we don't just connect people-we orchestrate opportunities. An intelligent, skill-driven ecosystem is designed to break barriers between talent and businesses, enabling seamless collaboration in a rapidly evolving world.</p>
                        <p style={{ color: 'white', fontSize: '20px', lineHeight: '1.6' }}>Through the WeTasker Partner Network (WPN), a dynamic network of industry leaders, innovators, and experts is cultivated. WPN is about synergy, ensuring the right expertise meets the right challenge at the right time, not just partnerships.</p>
                        <p style={{ color: 'white', fontSize: '20px', lineHeight: '1.6' }}> WeTasker is more than a platform—it's a movement, a revolution in how work gets done, where skills, innovation, and ambition unite to shape the future. Join WeTasker to redefine success. </p>
                        </div>
                    </section>
                    <section id="partner-types" style={{ color: 'white', textAlign: 'center', marginTop: '50px', paddingTop:'100px',backgroundColor:'#121110',borderRadius:'20px' }}>
                        <h2 style={{ color: 'white', fontSize: '35px', fontWeight: 'bold' }}>Partner Types</h2>
                        <div className="container py-5">
                            <div className="row">
                                {/* First column: Independent Software Vendor and Solution Adviser */}
                                <div className="col-12 col-md-6 mb-4">
                                <img style={{ width: '240px' ,height:'200px',marginBottom:'10px' }} src="images/common/vendor.png" alt="vendor" />
                                    <h2 style={{ color: 'white', fontSize: '25px', fontWeight: 'bold' }}>Independent Software Vendor</h2>
                                    <p style={{ color: 'white', fontSize: '20px', lineHeight: '1.6' }}>
                                        Partners who develop, market and deliver WeTasker-powered solutions, leveraging Intelligent workforce matching and skill-based optimization to empower businesses and professionals across industries.
                                    </p>
                                </div>

                                <div className="col-12 col-md-6 mb-4">
                                    <img style={{ width: '220px' ,height:'200px',marginBottom:'10px' }} src="images/common/solutionAdviser.png" alt="Solution adviser" />
                                    <h2 style={{ color: 'white', fontSize: '25px', fontWeight: 'bold' }}>Solution Adviser</h2>
                                    <p style={{ color: 'white', fontSize: '20px', lineHeight: '1.6' }}>
                                        Partners whose primary business model is to provide consultation services and expert guidance to clients seeking to implement WeTasker-driven workforce solutions, skill-based matching, and operational optimization.
                                    </p>
                                </div>
                            </div>

                            <div className="row">
                                {/* Second column: Service Delivery Partner and Solution Provider */}
                                <div className="col-12 col-md-6 mb-4">
                                    <img style={{ width: '220px' ,height:'200px',marginBottom:'10px' }} src="images/common/shakeHand.png" alt="Service Delivery Partner" />
                                    <h2 style={{ color: 'white', fontSize: '25px', fontWeight: 'bold' }}>Service Delivery Partner</h2>
                                    <p style={{ color: 'white', fontSize: '20px', lineHeight: '1.6' }}>
                                        Partners whose primary business is to deliver professional services and who seek to formalize or expand their expertise in WeTasker-driven workforce solutions, talent optimization, and operational excellence.
                                    </p>
                                </div>

                                <div className="col-12 col-md-6 mb-4">
                                <img style={{ width: '240px' ,height:'200px',marginBottom:'10px' }} src="images/common/provider.png" alt="provider" />
                                    <h2 style={{ color: 'white', fontSize: '25px', fontWeight: 'bold' }}>Solution Provider</h2>
                                    <p style={{ color: 'white', fontSize: '20px', lineHeight: '1.6' }}>
                                        Partners who specialize in value-added reselling, including seamless integration and optimization of WeTasker-powered workforce solutions and technologies.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="partner-levels" style={{ color: 'white', textAlign: 'center', marginTop: '50px',paddingTop:'100px' }}>
                        <h2 style={{ color: 'white', fontSize: '35px', fontWeight: 'bold' }}>Partner Levels</h2>
                        <div className="container py-5">
                            <div className="row d-flex align-items-stretch">
                                <div className='col-12 col-md-4 p-3'>
                                    <div className="p-3" style={{ backgroundColor: '#6e6c68', borderRadius: '20px' }}>
                                        <h2 style={{ color: 'white', fontSize: '25px', fontWeight: 'bold' }}>Registered</h2>
                                        <p style={{ color: 'white', fontSize: '20px', lineHeight: '1.6' }}>
                                            WeTasker's Registered Partner program
                                            provides an entry point for professionals
                                            and businesses to access exclusive
                                            training, sales enablement, and
                                            marketing tools, fostering skill growth
                                            and gig opportunities across various
                                            industries.
                                        </p>
                                    </div>
                                </div>
                                <div className='col-12 col-md-4 p-3'>
                                    <div className="p-3" style={{ backgroundColor: '#6e6c68', borderRadius: '20px' }}>
                                        <h2 style={{ color: 'white', fontSize: '25px', fontWeight: 'bold' }}>Preferred</h2>
                                        <p style={{ color: 'white', fontSize: '20px', lineHeight: '1.6' }}>
                                        WeTasker's Preferred Partner program fosters a deeper collaboration by rewarding professionals and businesses who achieve training, revenue, and performance goals with exclusive benefits, premium opportunities, and enhanced visibility.
                                        </p>
                                    </div>
                                </div>
                                <div className='col-12 col-md-4 p-3'>
                                    <div className="p-3" style={{ backgroundColor: '#6e6c68', borderRadius: '20px' }}>
                                        <h2 style={{ color: 'white', fontSize: '25px', fontWeight: 'bold' }}>Elite</h2>
                                        <p style={{ color: 'white', fontSize: '20px', lineHeight: '1.6' }}>
                                        WeTasker's Elite Partner program represents the highest level of commitment, offering top-tier professionals and businesses exclusive benefits, premium gigs, and priority access to advanced opportunities
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="customers" style={{ color: 'white', textAlign: 'center', marginTop: '50px' ,paddingTop:'100px'}}>
                        <h2 style={{ color: 'white', fontSize: '35px', fontWeight: 'bold' }}>Our Customers</h2>
                        <div style={{borderRadius:'20px'}}>
                        <img style={{ width: '90%' ,height:'60%',marginBottom:'10px',borderRadius:'20px' }} src="images/common/customers.png" alt="customer" />
                        </div>
                    </section>
                </div>
                <footer id="footer-releasenote" style={{ backgroundColor: webProperties?.PRIMARY_COLOR !== "#47974b" ? webProperties?.PRIMARY_COLOR : '#47974b',marginTop:'0px' }}>
                    <div style={{ color: 'white' }}>
                        {webProperties?.APP_NAME || APP_NAME} © Copyright {new Date().getFullYear()}. All Rights Reserved.
                    </div>
                </footer>
            </div>
        </Layout>

    )
}
